// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  identityPoolId: 'us-west-2:6d524d68-4895-4573-b53b-c99439fb52e2',
  cognitoClientId: '1g0csle5mj5cuacrf5cjd6epbb',
  cognitoPoolId: 'us-west-2_DwF3PeK91',
  bucketName: 'techforteletherapy',
  region: 'us-west-2',

  graphqlAPI: {
    awsAppsyncGraphqlEndpoint: 'https://zalthxjhjbd7vkyt3wxkqijpxe.appsync-api.us-west-2.amazonaws.com/graphql',
    awsAppsyncRegion: 'us-west-2',
    awsAppsyncAuthenticationType: 'AWS_IAM',
    awsAppsyncApiKey: 'da2-j5xyz3n3mvcvplviogaaojmu3u'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
