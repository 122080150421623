import { Component } from '@angular/core';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'MY DASHBOARD', url: '/dashboard', icon: 'bar-chart' },
    { title: 'MY CLIENTS', url: '/clients', icon: 'people' },
    { title: 'SLIDES', url: '/slides', icon: 'image' },
    { title: 'GAMES', url: '/games', icon: 'trophy' },
    { title: 'SESSIONS', url: '/sessions', icon: 'link' },
  ];
  constructor(public app: AppService) {


  }
}
