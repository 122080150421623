import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'games',
    loadChildren: () => import('./games/games.module').then( m => m.GamesPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'sessions',
    loadChildren: () => import('./sessions/sessions.module').then( m => m.SessionsPageModule)
  },
  {
    path: 'sessions/:id',
    loadChildren: () => import('./sessions/sessions.module').then( m => m.SessionsPageModule)
  },
  {
    path: 'sessions/:id/:sessionId',
    loadChildren: () => import('./sessions/sessions.module').then( m => m.SessionsPageModule)
  },
  {
    path: 'account-edit',
    loadChildren: () => import('./account-edit/account-edit.module').then( m => m.AccountEditPageModule)
  },
  {
    path: 'slides-edit/:id',
    loadChildren: () => import('./slides-edit/slides-edit.module').then( m => m.SlidesEditPageModule)
  },
  {
    path: 'slides-edit/:id/:folder',
    loadChildren: () => import('./slides-edit/slides-edit.module').then( m => m.SlidesEditPageModule)
  },
  {
    path: 'slides',
    loadChildren: () => import('./slides/slides.module').then( m => m.SlidesPageModule)
  },
  {
    path: 'sessions-operator',
    loadChildren: () => import('./sessions-operator/sessions-operator.module').then( m => m.SessionsOperatorPageModule)
  },
  {
    path: 'sessions-operator/:id',
    loadChildren: () => import('./sessions-operator/sessions-operator.module').then( m => m.SessionsOperatorPageModule)
  },
  {
    path: 'sessions-viewer',
    loadChildren: () => import('./sessions-viewer/sessions-viewer.module').then( m => m.SessionsViewerPageModule)
  },
  {
    path: 'sessions-viewer/:id',
    loadChildren: () => import('./sessions-viewer/sessions-viewer.module').then( m => m.SessionsViewerPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
