import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
const { graphqlAPI } = environment;

//amplify
import Amplify from '@aws-amplify/core';
// in this way you are only importing Auth and configuring it.
Amplify.configure({
    Auth: {
        mandatorySignId: false,
        region: environment.region,
        userPoolId: environment.cognitoPoolId,
        userPoolWebClientId: environment.cognitoClientId,
        identityPoolId: environment.identityPoolId
    },
    aws_appsync_graphqlEndpoint: graphqlAPI.awsAppsyncGraphqlEndpoint,
    aws_appsync_region: graphqlAPI.awsAppsyncRegion,
    aws_appsync_authenticationType: graphqlAPI.awsAppsyncAuthenticationType,
    aws_appsync_apiKey: graphqlAPI.awsAppsyncApiKey,
    API: {
        endpoints: [
            {
                name: "techfortelethe",
                endpoint: "https://74qqjb8mri.execute-api.us-west-2.amazonaws.com/default/v1/",
                region: 'us-west-2'
            }
        ]
    },
    Storage: {
        AWSS3: {
            bucket: environment.bucketName,
            region: environment.region,
            identityPoolId: environment.identityPoolId
        }
        // customPrefix: {
        //   public: '',
        //   protected: '',
        //   private: ''
        // }
    }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
